import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  pageChangeDelay,
  revit,
  vectors,
  visualisation,
} from "../../utils/urls";

function DetailData({ data }) {
  //
  //
  //
  let navigateLink = useNavigate();

  const [activeURL, setActiveURL] = useState("");

  useEffect(() => {
    let currentCategory = data?.data?.category;

    if (currentCategory.includes("Architecture Visualisation")) {
      setActiveURL(visualisation);
    } else if (currentCategory.includes("Revit")) {
      setActiveURL(revit);
    } else if (currentCategory.includes("Vectors")) {
      setActiveURL(vectors);
    }
  }, [window.location.pathname]);

  const handleAnimation = () => {
    let element = document.getElementById("testing");
    element.classList.add("open-container");
    let textChange = document.getElementById("loading-text");
    textChange.classList.add("display-text");
  };
  return (
    <>
      <section className="detail-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "32px",
                }}
              >
                <div className="colour-box">
                  <div className={`colour-circle ${activeURL}-1`}></div>
                  <div className={`colour-circle ${activeURL}-2`}></div>
                  <div className={`colour-circle ${activeURL}-3`}></div>
                  <div className={`colour-circle ${activeURL}-4`}></div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  className="detail-page-category detail-visualisation"
                  onClick={() => {
                    setTimeout(
                      () => navigateLink(`/${activeURL}`),
                      pageChangeDelay
                    );
                    handleAnimation();
                  }}
                >
                  {data?.data?.category}
                </a>
              </div>
              <h1 className="detail-page-heading">
                {data?.data?.name[0]?.text}
              </h1>
              <h6 className="detail-page-description">
                {data?.data?.description[0]?.text}
              </h6>
            </div>
          </div>
        </div>
      </section>

      <section className="detail-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h1 className="detail-page-heading">Images</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                {data?.data?.images?.map((i, index) => (
                  <div className="col-lg-6" key={index}>
                    <img
                      src={i?.img?.url}
                      className="detail-img"
                      alt="portfolio"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailData;
