import React, { useEffect, useState } from "react";

import { usePrismicDocumentByUID } from "@prismicio/react";
import BlurBackdrop from "../../components/BlurBackdrop";
import ProjectsOverlayImage from "./ProjectsOverlayImage";
import OverlayCloseButton from "../../components/OverlayCloseButton";
import { revit, vectors, visualisation } from "../../utils/urls";

function ProjectsOverlay({
  display,
  setDisplay,
  setBodyScroll,
  bodyScroll,
  resultIndex,
  projectData,
  currentURL,
}) {
  const [blurClass, setBlurClass] = useState("");

  useEffect(() => {
    if (currentURL === vectors) {
      setBlurClass("overlay-red");
    } else if (currentURL === visualisation) {
      setBlurClass("overlay-green");
    } else if (currentURL === revit) {
      setBlurClass("overlay-yellow");
    }
  }, [display]);
  return (
    <>
      <BlurBackdrop currentURL={currentURL} display={display} />
      <div
        className={`overlay-blur overlay ${blurClass} ${
          display ? "add-animation" : "remove-animation"
        }`}
        style={{ overflow: "scroll" }}
      >
        <OverlayCloseButton
          setDisplay={setDisplay}
          setBodyScroll={setBodyScroll}
        />
        {bodyScroll === true && resultIndex !== false ? (
          <ProjectsOverlayImage
            projectData={projectData}
            resultIndex={resultIndex}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ProjectsOverlay;
