import React from "react";

function Loader() {
  return (
    <div className="loading-container" id="testing">
      <div className="rounded-wrap top-wrap">
        <div className="rounded-div"></div>
      </div>
      <div className="loading-screen">
        <h2 className="loading-text" id="loading-text">
          {/* <span className="changing-text" id="loading-text"></span> */}
        </h2>
      </div>
      <div className="rounded-wrap bottom-wrap">
        <div className="rounded-div"></div>
      </div>
    </div>
  );
}

export default Loader;
