import React from "react";

function Testing({}) {
  return (
    <div className="loading-container" id="testing">
      <div className="rounded-wrap top-wrap"></div>
      <div className="loading-screen">
        <h2 className="loading-text">
          <span className="changing-text" id="changingText"></span>
        </h2>
      </div>
      <div className="rounded-wrap bottom-wrap"></div>
    </div>
  );
}

export default Testing;
