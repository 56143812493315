import React from "react";
import resume from "../assets/images/Resume - Noor Khan.pdf";

function Footer() {
  const year = new Date();
  return (
    <footer className="footer-class" id="footer-class">
      <div className="row">
        <div className="col-lg-3">
          <a
            href="https://www.linkedin.com/in/noor-ul-huda-khan-b26b36197/"
            rel="noreferrer"
            target="_blank"
            className="footer-link"
          >
            Linkedin
          </a>
        </div>
        <div className="col-lg-3">
          <a
            href="https://www.upwork.com/freelancers/~01a6cb2f7381dcf926"
            rel="noreferrer"
            target="_blank"
            className="footer-link"
          >
            Upwork
          </a>
        </div>
        <div className="col-lg-3">
          <a
            href="mailto:contact@nuurkhan.com"
            rel="noreferrer"
            target="_blank"
            className="footer-link"
          >
            Email
          </a>
        </div>
        <div className="col-lg-3">
          <a
            href={resume}
            rel="noreferrer"
            target="_blank"
            className="footer-link"
          >
            Resume
          </a>
        </div>
      </div>
      <div className="row" style={{ marginTop: "72px" }}>
        <div className="col-lg-3">
          <p className="footer-text">All Rights Reserved</p>
        </div>
        <div className="col-lg-6">
          <p className="footer-text center-text">
            Copyright © {year.getFullYear()} - Noor Ul Huda Khan
          </p>
        </div>
        <div className="col-lg-3">
          <p className="footer-text right-text">
            Designed by{" "}
            <span style={{ textDecoration: "underline" }}>
              <a
                href="https://aurangg.com"
                style={{ color: "inherit" }}
                target="_blank"
                rel="noreferrer"
              >
                Aurangg
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
