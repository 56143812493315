import React, { useEffect, useState } from "react";
import visualizationImage from "../../assets/images/visualization-banner.png";
import vectorImage from "../../assets/images/vector-banner.png";
import revitImage from "../../assets/images/revit-banner.png";
import { revit, vectors, visualisation } from "../../utils/urls";

function ProjectsBanner({ currentURL }) {
  const [bannerClass, setBannerClass] = useState("");
  const [headingText, setHeadingText] = useState("");
  const [paragraphText, setParagraphText] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  useEffect(() => {
    if (currentURL === visualisation) {
      setBannerClass("visual-banner");
      setHeadingText("Visualization");
      setParagraphText(
        "I am skilled in using SketchUp, Lumion, and Adobe Photoshop to create realistic images and animations. My commitment to delivering high-quality work includes all project stages, meeting clients' needs and delivering on time and within budget."
      );
      setBannerImage(visualizationImage);
    }
    if (currentURL === vectors) {
      setBannerClass("vector-banner");
      setHeadingText("Vectors");
      setParagraphText(
        "In my portfolio, I showcase my vector graphic designs, including logos, icons, and illustrations, for diverse industries. I closely collaborate with clients, utilizing my skills and experience to create designs that reflect their vision and goals."
      );
      setBannerImage(vectorImage);
    }
    if (currentURL === revit) {
      setBannerClass("revit-banner");
      setHeadingText("Revit");
      setParagraphText(
        "I showcase my Revit skills through diverse projects such as building design, construction documentation, and project management in my portfolio. I collaborate with architects, engineers, and contractors to ensure accurate representation of building geometry, structure, and systems."
      );
      setBannerImage(revitImage);
    }
  }, [currentURL]);
  return (
    <section className={`projects-banner ${bannerClass}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <div className="visual-text">
              <h2 className="visual-heading">{headingText}</h2>
              <p className="visual-paragraph">{paragraphText}</p>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div
            className="col-lg-5"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div className="visual-banner-container">
              <img
                src={bannerImage}
                alt="visual"
                className="visual-banner-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsBanner;
