import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  about,
  pageChangeDelay,
  revit,
  vectors,
  visualisation,
} from "../utils/urls";
import cross from "../assets/images/cross.svg";

function Sidedrawer({ show, drawerToggleClickHandler, activeURL }) {
  const [sideDrawerBlur, setSideDrawerBlur] = useState("");

  useEffect(() => {
    if (activeURL === vectors) {
      setSideDrawerBlur("overlay-red");
    } else if (activeURL === visualisation) {
      setSideDrawerBlur("overlay-green");
    } else if (activeURL === revit) {
      setSideDrawerBlur("overlay-yellow");
    }
  });
  return (
    <nav
      className={`overlay-blur ${sideDrawerBlur} side-drawer ${
        show === true ? "open" : ""
      }`}
    >
      <div
        className="nav-circle"
        id="navbar-circle"
        style={{ zIndex: "1997" }}
        onClick={drawerToggleClickHandler}
      >
        <img src={cross} alt="cross" className="menu-image" />
      </div>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "active-class side-drawer-links" : "side-drawer-links"
            }
            onClick={() => {
              drawerToggleClickHandler();
            }}
            end
          >
            Home
          </NavLink>
          <NavLink
            to={`/${vectors}`}
            className={({ isActive }) =>
              isActive ? "active-class side-drawer-links" : "side-drawer-links"
            }
            onClick={drawerToggleClickHandler}
          >
            Vectors
          </NavLink>
          <NavLink
            to={`/${visualisation}`}
            className={({ isActive }) =>
              isActive ? "active-class side-drawer-links" : "side-drawer-links"
            }
            onClick={drawerToggleClickHandler}
          >
            Visualisation
          </NavLink>
          <NavLink
            to={`/${revit}`}
            className={({ isActive }) =>
              isActive ? "active-class side-drawer-links" : "side-drawer-links"
            }
            onClick={drawerToggleClickHandler}
          >
            Revit
          </NavLink>
          <NavLink
            to={`/${about}`}
            className={({ isActive }) =>
              isActive ? "active-class side-drawer-links" : "side-drawer-links"
            }
            onClick={drawerToggleClickHandler}
          >
            About
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Sidedrawer;
