import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading";
import left from "../../assets/images/left-arrow.svg";
import right from "../../assets/images/right-arrow.svg";
import ProjectsOverlay from "./ProjectsOverlay";
import ProjectsListing from "./ProjectsListing";

function ProjectsOverview({ projectData }) {
  const [id, setID] = useState(null);
  const [display, setDisplay] = useState(false);
  const [index, setIndex] = useState(0);
  const [imageLength, setImageLength] = useState(0);
  const [bodyScroll, setBodyScroll] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [resultIndex, setResultIndex] = useState(false);

  useEffect(() => {
    if (bodyScroll) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.body.style.margin = "0%";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [bodyScroll]);

  const currentURL = window.location.pathname.slice(1, 20);

  return (
    <section className="projects-section">
      <ProjectsOverlay
        bodyScroll={bodyScroll}
        setBodyScroll={setBodyScroll}
        display={display}
        setDisplay={setDisplay}
        // id={id}
        projectData={projectData}
        resultIndex={resultIndex}
        showOverlay={showOverlay}
        currentURL={currentURL}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Heading text="View all of my" heading="Architecture Projects" />
          </div>
        </div>
        <ProjectsListing
          setBodyScroll={setBodyScroll}
          setID={setID}
          setDisplay={setDisplay}
          setIndex={setIndex}
          projectData={projectData}
          setResultIndex={setResultIndex}
          setShowOverlay={setShowOverlay}
          currentURL={currentURL}
        />
      </div>
    </section>
  );
}

export default ProjectsOverview;
