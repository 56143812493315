import React, { useState } from "react";
import Heading from "../../components/Heading";
import sendBtnImage from "../../assets/images/send-btn.svg";
import Loader from "./Loader";
import ContactInputs from "./ContactInputs";
import ContactOverlay from "./ContactOverlay";

function Contact() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = React.useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  let nameLS = localStorage.getItem("name");
  let emailLS = localStorage.getItem("email");

  const onSubmit = async (event) => {
    event.preventDefault();

    // console.log(JSON.parse(nameLS), emailLS);

    if (name === "") {
      return;
    } else if (email === "") {
      return;
    } else if (message === "") {
      return;
    }

    setShowOverlay(true);

    if (emailLS === null && nameLS === null) {
      setLoading(true);
      const formData = new FormData(event.target);
      formData.append("access_key", "acf79ada-e266-4ecc-9cab-14d9626483ee");

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      }).then((res) => res.json());

      if (res.success) {
        setLoading(false);
        setShowOverlay(true);
        localStorage.setItem("email", JSON.stringify(email));
        localStorage.setItem("name", JSON.stringify(name));
        console.log("Success", res);
        setResult(res.message);
      } else {
        console.log("Error", res);
        setResult(res.message);
      }
    } else {
      setErrorDisplay(true);
    }
  };

  const handlecloseoverlay = (res) => {
    setShowOverlay(false);
    setName("");
    setEmail("");
    setMessage("");
    setSubject("");
  };

  return (
    <section className="contact-section">
      <div className="container-fluid">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-11">
              <Heading text="Get in touch" heading="Contact" />
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-10 offset-lg-1"
              style={{ marginTop: "80px" }}
            >
              <ContactInputs
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                subject={subject}
                setSubject={setSubject}
                message={message}
                setMessage={setMessage}
                sendBtnImage={sendBtnImage}
              />
              <ContactOverlay
                showOverlay={showOverlay}
                isLoading={isLoading}
                result={result}
                errorDisplay={errorDisplay}
                handlecloseoverlay={handlecloseoverlay}
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
