import React, { useEffect, useRef } from "react";
import noorDisplay from "../../assets/images/noor-display.png";
import noorDisplay1 from "../../assets/images/noor-display-1.png";
import noorDisplay2 from "../../assets/images/noor-display-2.png";
import gsap, { Power0 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function Banner() {
  let circleTrigger = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl.to(".banner-heading", {
      clipPath: "polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%)",
      opacity: 1,
      y: 0,
      duration: 0.2,
    });
    let backCircleTrigger = gsap.timeline({
      defaults: { ease: Power0.easeInOut, duration: 1 },
      scrollTrigger: {
        trigger: circleTrigger,
        start: "top bottom",
        scrub: 1,
      },
    });
    backCircleTrigger.to(".back-image", {
      rotation: "360",
    });
  });
  return (
    <section className="home-banner-section" ref={(el) => (circleTrigger = el)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-6 home-text">
            <div style={{ display: "flex" }}>
              <p className="banner-hi">Hello, I am</p>
              <p className="hand-shake">👋🏻</p>
            </div>
            <h2 className="banner-heading black900-color">Noor Ul Huda Khan</h2>
            <p className="banner-hi banner-description">
              Architect. Freelancer. 3D Visualiser. Vector Artist.
            </p>
            <a
              href="https://www.upwork.com/freelancers/~01a6cb2f7381dcf926"
              rel="noreferrer"
              target="_blank"
              className="main-btn w-240"
            >
              Hire Me
            </a>
          </div>
          <div className="col-lg-5 center-image">
            <img
              src={noorDisplay1}
              alt="noor-display"
              className="banner-image"
            />
            <img src={noorDisplay2} alt="noor-display" className="back-image" />
            {/* <img
              src={noorDisplay}
              alt="noor-display"
              className="banner-image"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
