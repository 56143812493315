import React from "react";
import close from "../assets/images/close.svg";

function OverlayCloseButton({ setDisplay, setBodyScroll }) {
  return (
    <div
      style={{ zIndex: "100" }}
      className="circle close-btn-position"
      onClick={() => {
        setDisplay(false);
        setBodyScroll(false);
      }}
    >
      <img src={close} alt="close-btn" className="close-btn" />
    </div>
  );
}

export default OverlayCloseButton;
