import React, { useEffect } from "react";
import aboutImage from "../../assets/images/about-hero.png";
import Footer from "../../components/Footer";
import { colourChangeDelay, removingAnimation } from "../../utils/urls";
import Contact from "../Contact/Contact";
import AboutMap from "./AboutMap";

function AboutMain() {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("footer-class").style.backgroundColor = "#fff";
      document.getElementById("navbar-main").style.backgroundColor = "#fff";
      document.getElementById("navbar-circle").style.backgroundColor = "#fff";
      document.body.style.backgroundColor = "#EDEDED";
      document.getElementById("link-visual").style.backgroundColor = "#fff";
      document.getElementById("link-vector").style.backgroundColor = "#fff";
      document.getElementById("link-about").style.backgroundColor = "#fff";
      document.getElementById("link-revit").style.backgroundColor = "#fff";
    }, colourChangeDelay);
  });
  useEffect(() => {
    document.title = "About | Noor ul Huda Khan";
    setTimeout(() => {
      document.getElementById("testing").classList.remove("open-container");
      document.getElementById("loading-text").classList.remove("display-text");
    }, removingAnimation);
  }, []);
  return (
    <>
      <section className="detail-section">
        <div className="container-fluid">
          <div className="row position-relative">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <img src={aboutImage} alt="about-img" className="about-img" />
            </div>
            <div className="col-lg-5">
              <div className="about-box">
                <h1 className="about-text">Greetings!</h1>
                <h2 className="about-text">
                  I'm a graduate Architect specializing in architecture
                  visualization, a Revit freelancer and a Vector Design
                  freelancer with over 3 years of experience. I provide clients
                  with stunning Architectural Visuals, Detailed Building
                  Documentation & Vector Design, developed with skill & passion.
                </h2>
                <h2 className="about-text">
                  I have experience using a range of software and tools,
                  including Adobe Illustrator and Photoshop, to create vector
                  graphics
                </h2>
                <h2 className="about-text">
                  I have experience using a range of software and tools,
                  including SketchUp, Lumion and Adobe Photoshop, to create
                  realistic and compelling images and animations.
                </h2>
                <h2 className="about-text">
                  My experience using Revit includes a deep understanding of the
                  software's capabilities and best practices.
                </h2>
              </div>
              <div style={{ width: "100%" }}>
                <AboutMap />
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <Contact />
      <Footer />
    </>
  );
}

export default AboutMain;
