import React from "react";
import menu from "../assets/images/menu.svg";

function ToggleMenu({ drawerToggleClickHandler }) {
  return (
    <div
      className="nav-circle"
      id="navbar-circle"
      // onClick={drawerToggleClickHandler}
      onClick={() => {
        drawerToggleClickHandler();
      }}
    >
      <img src={menu} alt="menu" className="menu-image" />
    </div>
  );
}

export default ToggleMenu;
