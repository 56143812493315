import React, { useEffect, useState } from "react";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import ProjectsBanner from "../Projects/ProjectsBanner";
import ProjectsOverview from "../Projects/ProjectsOverview";
import {
  colourChangeDelay,
  removingAnimation,
  vectors,
} from "../../utils/urls";
import Footer from "../../components/Footer";
import gsap from "gsap";

function VectorsMain() {
  const currentURL = window.location.pathname.slice(1, 20);
  const [data] = useAllPrismicDocumentsByType("projects");
  const projectData = data?.filter((categoryIndex) =>
    categoryIndex?.data?.category
      .toLowerCase()
      .includes(currentURL.toLowerCase())
  );

  useEffect(() => {
    document.title = "Vectors | Noor ul Huda Khan";
    setTimeout(() => {
      document.getElementById("testing").classList.remove("open-container");
      document.getElementById("loading-text").classList.remove("display-text");
    }, removingAnimation);
  }, []);

  useEffect(() => {
    if (currentURL === vectors) {
      setTimeout(() => {
        document.body.style.backgroundColor = "#fff5f5";
        document.getElementById("navbar-main").style.backgroundColor =
          "#ffdbcf";
        document.getElementById("navbar-circle").style.backgroundColor =
          "#ffdbcf";
        document.getElementById("link-vector").style.backgroundColor =
          "#efb6a3";
        document.getElementById("link-visual").style.backgroundColor =
          "#ffdbcf";
        document.getElementById("link-about").style.backgroundColor = "#ffdbcf";
        document.getElementById("link-revit").style.backgroundColor = "#ffdbcf";
        document.getElementById("footer-class").style.backgroundColor =
          "#FFE8E8";
      }, colourChangeDelay);
    }
  });

  return (
    <>
      <div className="vector-main">
        <ProjectsBanner currentURL={currentURL} />
        <ProjectsOverview projectData={projectData} />
      </div>
      <Footer />
    </>
  );
}

export default VectorsMain;
