import React, { useEffect, useState } from "react";
import ProjectsBanner from "./ProjectsBanner";
import {
  colourChangeDelay,
  removingAnimation,
  vectors,
  visualisation,
} from "../../utils/urls";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import ProjectsOverview from "./ProjectsOverview";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import FirstLoader from "../../components/FirstLoader";
import gsap from "gsap";

function ProjectsMain() {
  const currentURL = window.location.pathname.slice(1, 20);
  const [data] = useAllPrismicDocumentsByType("projects");

  useEffect(() => {
    document.title = "Visualisation | Noor ul Huda Khan";
    setTimeout(() => {
      document.getElementById("testing").classList.remove("open-container");
      document.getElementById("loading-text").classList.remove("display-text");
    }, removingAnimation);
  }, [currentURL]);

  useEffect(() => {
    if (currentURL === visualisation) {
      setTimeout(() => {
        document.getElementById("footer-class").style.backgroundColor =
          "#F1F3E8";
        document.getElementById("navbar-main").style.backgroundColor =
          "#D9E7CA";
        document.getElementById("navbar-circle").style.backgroundColor =
          "#D9E7CA";
        document.getElementById("link-visual").style.backgroundColor =
          "#b5d099";
        document.getElementById("link-vector").style.backgroundColor =
          "#d9e7ca";
        document.getElementById("link-revit").style.backgroundColor = "#d9e7ca";
        document.getElementById("link-about").style.backgroundColor = "#d9e7ca";
        document.body.style.backgroundColor = "#FDFDF6";
      }, colourChangeDelay);
    }
  });

  const projectData = data?.filter((categoryIndex) =>
    categoryIndex?.data?.category
      .toLowerCase()
      .includes(currentURL.toLowerCase())
  );

  return (
    <>
      <div className="visualisation-main">
        <ProjectsBanner currentURL={currentURL} />
        <ProjectsOverview projectData={projectData} />
      </div>
      <Footer />
    </>
  );
}

export default ProjectsMain;
