import React, { useState } from "react";
import right from "../../assets/images/right-arrow.svg";
import { revit, vectors, visualisation } from "../../utils/urls";

function ProjectsListing({
  projectData,
  setBodyScroll,
  setID,
  setDisplay,
  setIndex,
  setResultIndex,
  setShowOverlay,
  currentURL,
}) {
  const [cardBox, setCardBox] = useState("");

  useState(() => {
    if (currentURL === vectors) {
      setCardBox("card-vector-box");
    } else if (currentURL === revit) {
      setCardBox("card-revit-box");
    } else if (currentURL === visualisation) {
      setCardBox("card-visualisation-box");
    }
  });

  return (
    <div className="row" style={{ marginTop: "64px" }}>
      {projectData?.map((i, index) => (
        <div className="col-lg-12" key={index}>
          <div
            className={`card-box ${cardBox}`}
            onClick={() => {
              setBodyScroll(true);
              setDisplay(true);
              setResultIndex(index);
              setShowOverlay(true);
              // setID(i?.uid);
              // setIndex(index);
              // setImageLength(i?.images?.length);
            }}
          >
            <div className="card-container">
              <img
                src={i?.data?.images[0]?.img?.url}
                // src={test}
                alt="project"
                className="image"
              />
            </div>
            <div className="card-padding">
              <div className="card-box-distribution">
                <div>
                  <h3 className="title">{i?.data?.name[0]?.text}</h3>
                  <p className="description">{i?.data?.description[0]?.text}</p>
                </div>
                <div
                  className={`view-more-btn ${
                    currentURL === revit ? "btn-revit" : ""
                  }`}
                >
                  View
                  <img src={right} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProjectsListing;
