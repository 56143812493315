import React, { useEffect, useState } from "react";
import { revit, vectors, visualisation } from "../utils/urls";

function BlurBackdrop({ display, currentURL }) {
  const [backdrop, setBackdrop] = useState("");

  useEffect(() => {
    if (currentURL === vectors) {
      setBackdrop("red-backdrop");
    } else if (currentURL === visualisation) {
      setBackdrop("green-backdrop");
    } else if (currentURL === revit) {
      setBackdrop("yellow-backdrop");
    }
  });
  return (
    <div
      className={`overlay-backdrop ${
        display ? `open-animation ${backdrop}` : "close-animation"
      }`}
    ></div>
  );
}

export default BlurBackdrop;
