import React, { useEffect, useState } from "react";
import ToggleMenu from "./ToggleMenu";
import menu from "../assets/images/menu.svg";
import NavbarLinks from "./NavbarLinks";
import { revit, vectors, visualisation } from "../utils/urls";
import Loader from "../components/Loader";
import Sidedrawer from "./Sidedrawer";

function Navbar({ activeURL }) {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  function drawerToggleClickHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }

  return (
    <>
      <Loader />
      <header className="header">
        <>
          <div className="navbar-main" id="navbar-main">
            <NavbarLinks activeURL={activeURL} />
          </div>
        </>
      </header>
      <ToggleMenu drawerToggleClickHandler={drawerToggleClickHandler} />
      <Sidedrawer
        show={sideDrawerOpen}
        drawerToggleClickHandler={drawerToggleClickHandler}
        activeURL={activeURL}
      />
    </>
  );
}

export default Navbar;
