import React from "react";

function DataLoader() {
  return (
    <div className="loader-container">
      <div className="data-loader"></div>
    </div>
  );
}

export default DataLoader;
