import React from "react";

function ContactInputs({
  email,
  setEmail,
  name,
  setName,
  subject,
  setSubject,
  message,
  setMessage,
  sendBtnImage,
}) {
  return (
    <div className="row">
      <div className="col-lg-4">
        <input
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-class"
          type="email"
          placeholder="Email"
        />
      </div>
      <div className="col-lg-4">
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input-class"
          type="name"
          placeholder="Name"
        />
      </div>
      <div className="col-lg-4">
        <input
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="input-class"
          type="text"
          placeholder="Subject"
        />
      </div>
      <div className="col-lg-8">
        <textarea
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="input-class text-area"
          type="text"
          placeholder="Your Message"
        />
      </div>
      <div className="col-lg-4">
        <button className="send-btn text-area">
          <img
            src={sendBtnImage}
            alt="send-btn"
            style={{ marginBottom: "8px" }}
          />
          Send
        </button>
      </div>
    </div>
  );
}

export default ContactInputs;
