import React, { useEffect } from "react";
import expo from "../../assets/images/experiment-2.png";
import gsap, { Power0 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef } from "react";

function ServiceBanner({}) {
  let circle = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let leftCardTrigger = gsap.timeline({
      defaults: { ease: Power0.easeInOut, duration: 1 },
      scrollTrigger: {
        trigger: circle,
        start: "top bottom",
        scrub: 1,
      },
    });
    leftCardTrigger.to(".circle-img", {
      rotation: "360",
    });
  });
  return (
    <div className="col-lg-4 position-relative" ref={(el) => (circle = el)}>
      <div className="center-all" style={{ height: "100%" }}>
        <img src={expo} alt="expo" className="circle-img" />
      </div>
    </div>
  );
}

export default ServiceBanner;
