import React from "react";
import Loader from "./Loader";

function ContactOverlay({
  result,
  errorDisplay,
  handlecloseoverlay,
  showOverlay,
  isLoading,
}) {
  return (
    <div className={`overly center ${showOverlay ? "visible-overlay" : ""}`}>
      <div className="container center">
        <div className="row">
          <div className="col-lg-12">
            <div className="email-res-center">
              {isLoading ? <Loader /> : <></>}
              {errorDisplay === false ? (
                <>
                  {isLoading ? (
                    <></>
                  ) : (
                    <span className="email-result">{result} ♥️</span>
                  )}
                  <div
                    className="main-btn"
                    style={{ cursor: "pointer" }}
                    onClick={handlecloseoverlay}
                  >
                    Close
                  </div>
                </>
              ) : (
                <>
                  <span className="email-result">
                    I have already received your email. I will contact you soon.
                    ♥️
                  </span>
                  <div
                    className="main-btn"
                    style={{ cursor: "pointer" }}
                    onClick={handlecloseoverlay}
                  >
                    Close
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactOverlay;
