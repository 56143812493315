import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Contact from "../Contact/Contact";
import Banner from "./Banner";
import Services from "./Services";
import Work from "./Work";
import gsap from "gsap";
import { colourChangeDelay, removingAnimation } from "../../utils/urls";
import FirstLoader from "../../components/FirstLoader";
import { cookie } from "@prismicio/client";

function Home({}) {
  useEffect(() => {
    document.title = "Nuur-ul-Huda Khan";
    setTimeout(() => {
      document.getElementById("footer-class").style.backgroundColor = "#fff";
      document.getElementById("navbar-main").style.backgroundColor = "#fff";
      document.getElementById("navbar-circle").style.backgroundColor = "#fff";
      document.body.style.backgroundColor = "#EDEDED";
      document.getElementById("link-visual").style.backgroundColor = "#fff";
      document.getElementById("link-vector").style.backgroundColor = "#fff";
      document.getElementById("link-about").style.backgroundColor = "#fff";
      document.getElementById("link-revit").style.backgroundColor = "#fff";
    }, colourChangeDelay);
  });

  useEffect(() => {
    let mainSection = gsap.timeline({
      defaults: { ease: "Power3.easeInOut" },
    });
    mainSection.to(".main-section", {
      y: 0,
      duration: 1.4,
    });
  });
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("testing").classList.remove("open-container");
      document.getElementById("loading-text").classList.remove("display-text");
    }, removingAnimation);
  });

  // const [loader, setLoader] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoader(true);
  //   }, 2500);
  // }, [loader]);

  return (
    <>
      {/* {!loader && localStorage.getItem("cookie") !== "true" ? (
        <FirstLoader />
      ) : (
      )} */}
      <main>
        <div className="main-section">
          <Banner />
          <Work />
        </div>
        <Services />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
export default Home;
