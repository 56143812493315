import React from "react";
import { Link } from "react-router-dom";
import link from "../../assets/images/page-link.svg";
import { work } from "../../utils/urls";

function ProjectsOverlayImage({ projectData, resultIndex }) {
  let projectDataById = projectData[resultIndex];
  let dimension = "1.78";
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="project-heading-div">
            <h2 className="project-heading">
              {projectDataById?.data?.name[0]?.text}
            </h2>
            <Link
              to={`/${work}/${projectDataById?.uid}`}
              className="circle-mini"
            >
              <img src={link} alt="mini-link" />
            </Link>
          </div>
          <p className="project-description">
            {projectDataById?.data?.description[0]?.text}
          </p>
        </div>
        {projectDataById?.data?.images.map((i, index) => (
          <React.Fragment key={index}>
            {Number(
              i?.img?.dimensions?.width / i?.img?.dimensions?.height
            ).toFixed(2) === dimension ? (
              <div className="col-lg-12">
                <div className="project-image">
                  <img
                    className="image-style"
                    src={i?.img?.url}
                    alt="full-info"
                    loading="lazy"
                  />
                </div>
              </div>
            ) : (
              <div className="col-lg-6">
                <div className="project-image">
                  <img
                    className="image-style"
                    src={i?.img?.url}
                    alt="info"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default ProjectsOverlayImage;
