import React from "react";
import Heading from "../../components/Heading";
import vectorImage from "../../assets/images/vector-main-image.png";
import visualizationImage from "../../assets/images/visualization-main-image.png";
import revitImage from "../../assets/images/revit-main-image.png";
import { Link, useNavigate } from "react-router-dom";
import {
  pageChangeDelay,
  revit,
  vectors,
  visualisation,
} from "../../utils/urls";

function Work({}) {
  let navigateLink = useNavigate();
  const handleAddClass = () => {
    let element = document.getElementById("testing");
    element.classList.add("open-container");
    let textChange = document.getElementById("loading-text");
    textChange.classList.add("display-text");
  };

  const setLoaderCookie = () => {
    localStorage.setItem("cookie", JSON.parse(true));
  };

  return (
    <section className="work-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <Heading text="The best of my" heading="Work" />
          </div>
        </div>
        <div
          className="col-lg-10 offset-lg-1"
          style={{ marginTop: "80px", paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="row">
            <div className="col-lg-4">
              <a
                onClick={() => {
                  setTimeout(
                    () => navigateLink(`/${vectors}`),
                    pageChangeDelay
                  );
                  handleAddClass();
                  setLoaderCookie();
                }}
              >
                <div className="work vector-bg">
                  <h2 className="heading">
                    Vectors
                    <span className="bar"></span>
                  </h2>
                  <div className="work-image-container">
                    <img
                      src={vectorImage}
                      className="work-image"
                      alt="vector"
                    />
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4">
              <a
                onClick={() => {
                  setTimeout(
                    () => navigateLink(`/${visualisation}`),
                    pageChangeDelay
                  );
                  handleAddClass();
                  setLoaderCookie();
                }}
              >
                <div className="work visualization-bg">
                  <h2 className="heading">
                    Visualization
                    <span className="bar"></span>
                  </h2>
                  <div className="work-image-container">
                    <img
                      src={visualizationImage}
                      className="work-image"
                      alt="visualization"
                    />
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-4">
              <a
                onClick={() => {
                  setTimeout(() => navigateLink(`/${revit}`), pageChangeDelay);
                  handleAddClass();
                  setLoaderCookie();
                }}
              >
                <div className="work revit-bg">
                  <h2 className="heading">
                    Revit
                    <span className="bar"></span>
                  </h2>
                  <div className="work-image-container">
                    <img src={revitImage} className="work-image" alt="revit" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
