import React, { useEffect, useRef } from "react";
import gsap, { Power0 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import icon1 from "../../assets/images/Icons_3D modelling.svg";
import icon2 from "../../assets/images/Icons_Revit.svg";
import icon3 from "../../assets/images/Icons_Vector.svg";

function ServiceScroll() {
  gsap.registerPlugin(ScrollTrigger);

  let selectedRow = useRef(null);
  let extension1 = useRef(null);
  let extension2 = useRef(null);
  let extension3 = useRef(null);
  let leftCard = useRef(null);
  let rightCard = useRef(null);
  let leftCard2 = useRef(null);
  let rightCard2 = useRef(null);
  let leftCard3 = useRef(null);
  let rightCard3 = useRef(null);

  useEffect(() => {
    let extensionTrigger1 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 80%",
      },
    });
    extensionTrigger1.to(extension1, {
      opacity: "1",
      right: "-200px",
    });

    let extensionTrigger2 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 80%",
      },
    });
    extensionTrigger2.to(extension2, {
      opacity: "1",
      right: "-200px",
    });

    let extensionTrigger3 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 80%",
      },
    });
    extensionTrigger3.to(extension3, {
      opacity: "1",
      right: "-200px",
    });

    let leftCardTrigger = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 30%",
      },
    });
    leftCardTrigger.to(leftCard, {
      transform: "translateX(0px)",
    });

    let rightCardTrigger = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 30%",
      },
    });
    rightCardTrigger.to(rightCard, {
      transform: "translateX(0px)",
    });

    let leftCardTrigger2 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.8, delay: 0.3 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 32%",
      },
    });
    leftCardTrigger2.to(leftCard2, {
      transform: "translateX(0px)",
    });

    let rightCardTrigger2 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 0.8, delay: 0.3 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 32%",
      },
    });
    rightCardTrigger2.to(rightCard2, {
      transform: "translateX(0px)",
    });

    let leftCardTrigger3 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 1.2, delay: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 30%",
      },
    });
    leftCardTrigger3.to(leftCard3, {
      transform: "translateX(0px)",
    });

    let rightCardTrigger3 = gsap.timeline({
      defaults: { ease: Power0.easeNone, duration: 1.2, delay: 0.6 },
      scrollTrigger: {
        trigger: selectedRow,
        scrub: 1,
        end: "top 30%",
      },
    });
    rightCardTrigger3.to(rightCard3, {
      transform: "translateX(0px)",
    });
  }, []);

  return (
    <div className="row" ref={(el) => (selectedRow = el)}>
      <div className="col-lg-6 service-mb position-relative">
        <div
          className="service-box vertical-center left-box"
          ref={(el) => (leftCard = el)}
        >
          <img src={icon1} alt="architecture" className="icon" />
          <div className="left-mini-card">
            <h6 className="mini-heading">Visualisation with love!</h6>
            <p className="mini-description">
              I have experience using a range of software and tools, including
              SketchUp, Lumion and Adobe Photoshop, to create realistic and
              compelling images and animations. My commitment to delivering
              high-quality work extends to all aspects of the project, from
              initial concept to final delivery. I am dedicated to meeting my
              clients' needs and exceeding their expectations, and I strive to
              deliver projects on time and within budget.
            </p>
          </div>
        </div>
        <div className="extension" ref={(el) => (extension1 = el)}></div>
      </div>

      <div className="col-lg-6 service-mb">
        <div
          className="service-box center-all right-box"
          ref={(el) => (rightCard = el)}
        >
          <p className="sub-heading">Visuals & Animations</p>
          <h2 className="heading">3D Visualisation</h2>
        </div>
      </div>

      <div className="col-lg-6 service-mb position-relative">
        <div
          className="service-box vertical-center left-box"
          ref={(el) => (leftCard2 = el)}
        >
          <img src={icon2} alt="architecture" className="icon" />
          <div className="left-mini-card">
            <h6 className="mini-heading">Revit</h6>
            <p className="mini-description">
              My portfolio includes a range of projects that demonstrate my
              ability to use Revit for various purposes, including building
              design, construction documentation, and project management. I have
              experience working with architects, engineers, and contractors,
              collaborating to ensure that the model accurately represents the
              building's geometry, structure, and systems.
            </p>
          </div>
        </div>
        <div className="extension" ref={(el) => (extension2 = el)}></div>
      </div>

      <div className="col-lg-6 service-mb">
        <div
          className="service-box center-all right-box"
          ref={(el) => (rightCard2 = el)}
        >
          <p className="sub-heading">Geometry & Systems</p>
          <h2 className="heading">Revit</h2>
        </div>
      </div>

      <div className="col-lg-6 service-mb position-relative">
        <div
          className="service-box vertical-center left-box"
          ref={(el) => (leftCard3 = el)}
        >
          <img src={icon3} alt="architecture" className="icon" />
          <div className="left-mini-card">
            <h6 className="mini-heading">Vectors</h6>
            <p className="mini-description">
              My portfolio includes a range of projects that demonstrate my
              ability to create vector graphics for various industries,
              including logos, icons, illustrations, and infographics. I work
              closely with my clients to understand their specific requirements
              and preferences, using my skills and experience to create designs
              that reflect their vision and goals.
            </p>
          </div>
        </div>
        <div className="extension" ref={(el) => (extension3 = el)}></div>
      </div>

      <div className="col-lg-6 service-mb">
        <div
          className="service-box center-all right-box"
          ref={(el) => (rightCard3 = el)}
        >
          <p className="sub-heading">Vectors & Logos</p>
          <h2 className="heading">Vector Illustrations</h2>
        </div>
      </div>
    </div>
  );
}

export default ServiceScroll;
