import React, { useEffect, useLayoutEffect } from "react";
import Heading from "../../components/Heading";
import { services } from "../../utils/Data";
import ServiceScroll from "./ServiceScroll";
import expo from "../../assets/images/experiment-2.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef } from "react";
import ServiceBanner from "./ServiceBanner";

function Services() {
  let entryPoint = useRef(null);

  // gsap.registerPlugin(ScrollTrigger);
  // useEffect(() => {
  //   let animationTrigger = gsap.timeline({
  //     defaults: { ease: "none", duration: 0.9 },
  //     scrollTrigger: {
  //       trigger: entryPoint,
  //       scrub: 1,
  //     },
  //   });
  //   animationTrigger.to(".circle-img", {
  //     rotation: "360",
  //   });
  // }, []);
  return (
    <section className="">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="services-section">
              <div className="row" ref={(el) => (entryPoint = el)}>
                <div className="col-lg-1"></div>
                <div className="col-lg-5">
                  <div className="service-adj">
                    <Heading
                      text="What I do"
                      heading="Provide clients with stunning Architectural Visuals, Detailed Building Documentation & Vector Design, developed with skill & passion."
                    />
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <ServiceBanner />
              </div>
            </div>
          </div>
        </div>
        <ServiceScroll entryPoint={entryPoint} />
      </div>
    </section>
  );
}

export default Services;
