import React from "react";

function Heading({ text, heading }) {
  return (
    <>
      <p className="banner-hi">{text}</p>
      <h2 className="section-heading">{heading}</h2>
    </>
  );
}

export default Heading;
