import React, { useEffect, useState } from "react";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import ProjectsBanner from "../Projects/ProjectsBanner";
import ProjectsOverview from "../Projects/ProjectsOverview";
import { colourChangeDelay, removingAnimation, revit } from "../../utils/urls";
import Footer from "../../components/Footer";
import FirstLoader from "../../components/FirstLoader";
import gsap from "gsap";

function RevitMain() {
  const currentURL = window.location.pathname.slice(1, 20);
  const [data] = useAllPrismicDocumentsByType("projects");
  const projectData = data?.filter((categoryIndex) =>
    categoryIndex?.data?.category
      .toLowerCase()
      .includes(currentURL.toLowerCase())
  );

  useEffect(() => {
    document.title = "Revit | Noor ul Huda Khan";
    setTimeout(() => {
      document.getElementById("testing").classList.remove("open-container");
      document.getElementById("loading-text").classList.remove("display-text");
    }, removingAnimation);
  }, []);

  useEffect(() => {
    if (currentURL === revit) {
      setTimeout(() => {
        document.body.style.backgroundColor = "#FFFCF3";
        document.getElementById("footer-class").style.backgroundColor =
          "#F4F1E4";
        document.getElementById("navbar-main").style.backgroundColor =
          "#E6E4BF";
        document.getElementById("navbar-circle").style.backgroundColor =
          "#E6E4BF";
        document.getElementById("link-vector").style.backgroundColor =
          "#E6E4BF";
        document.getElementById("link-about").style.backgroundColor = "#E6E4BF";
        document.getElementById("link-visual").style.backgroundColor =
          "#E6E4BF";
        document.getElementById("link-revit").style.backgroundColor = "#C8C6A1";
      }, colourChangeDelay);
    }
  });

  return (
    <>
      <div className="revit-main">
        <ProjectsBanner currentURL={currentURL} />
        <ProjectsOverview projectData={projectData} />
      </div>
      <Footer />
    </>
  );
}

export default RevitMain;
