import React, { useEffect } from "react";
import {
  usePrismicDocumentByUID,
  usePrismicDocumentsByType,
} from "@prismicio/react";
import { colourChangeDelay } from "../../utils/urls";
import DataLoader from "../../components/DataLoader";
import DetailData from "./DetailData";
import Footer from "../../components/Footer";

function DetailMain() {
  //
  //

  useEffect(() => {
    document.title = "Work | Noor ul Huda Khan";
    setTimeout(() => {
      document.body.style.backgroundColor = "#EDEDED";
      document.getElementById("navbar-main").style.backgroundColor = "#fff";
      document.getElementById("navbar-circle").style.backgroundColor = "#fff";
      document.getElementById("link-visual").style.backgroundColor = "#fff";
      document.getElementById("link-vector").style.backgroundColor = "#fff";
      document.getElementById("link-about").style.backgroundColor = "#fff";
      document.getElementById("link-revit").style.backgroundColor = "#fff";
    }, colourChangeDelay);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "scroll";
    document.body.style.height = "auto";
    document.body.style.margin = "auto";
  });

  let uid = window.location.pathname.slice(6);
  const [data] = usePrismicDocumentByUID("projects", uid);
  console.log(data);
  return (
    <>
      {data === undefined ? (
        <DataLoader />
      ) : (
        <>
          <DetailData data={data} /> <Footer />
        </>
      )}
    </>
  );
}

export default DetailMain;
