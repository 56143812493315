import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import {
  about,
  pageChangeDelay,
  revit,
  vectors,
  visualisation,
} from "../utils/urls";

function NavbarLinks({}) {
  let navigateLink = useNavigate();

  const handleTestClick = () => {
    let element = document.getElementById("testing");
    element.classList.add("open-container");
    let textChange = document.getElementById("loading-text");
    textChange.classList.add("display-text");
  };

  return (
    <>
      <div>
        <a
          onClick={() => {
            setTimeout(() => navigateLink("/"), pageChangeDelay);
            handleTestClick();
            localStorage.setItem("cookie", JSON.parse(true));
          }}
        >
          <img src={logo} alt="logo" className="logo-img" />
        </a>
      </div>
      <div className="navbar-links">
        <a
          className="link-item"
          id="link-vector"
          onClick={() => {
            setTimeout(() => navigateLink(`/${vectors}`), pageChangeDelay);
            handleTestClick();
            localStorage.setItem("cookie", JSON.parse(true));
          }}
        >
          Vectors
        </a>
        <a
          className="link-item"
          id="link-visual"
          onClick={() => {
            setTimeout(
              () => navigateLink(`/${visualisation}`),
              pageChangeDelay
            );
            localStorage.setItem("cookie", JSON.parse(true));
            handleTestClick();
          }}
        >
          Visualization
        </a>
        <a
          className="link-item"
          id="link-revit"
          onClick={() => {
            setTimeout(() => navigateLink(`/${revit}`), pageChangeDelay);
            localStorage.setItem("cookie", JSON.parse(true));
            handleTestClick();
          }}
        >
          Revit
        </a>
        <a
          className="link-item"
          id="link-about"
          onClick={() => {
            setTimeout(() => navigateLink(`/${about}`), pageChangeDelay);
            localStorage.setItem("cookie", JSON.parse(true));
            handleTestClick();
          }}
        >
          About
        </a>
      </div>
    </>
  );
}

export default NavbarLinks;
