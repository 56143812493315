import React, { useLayoutEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/global.css";
import "./assets/fonts/Helvetica Neu Bold.ttf";
import "./assets/fonts/HelveticaNeue Medium.ttf";
import "./assets/fonts/HelveticaNeue Thin.ttf";
import Lenis from "@studio-freight/lenis";
import Home from "./Pages/Home/Home";
import Navbar from "./Navbar/Navbar";
import { about, revit, vectors, visualisation, work } from "./utils/urls";
import { PrismicRichText, useFirstPrismicDocument } from "@prismicio/react";
import ProjectsMain from "./Pages/Projects/ProjectsMain";
import Loader from "./components/Loader";
import VectorsMain from "./Pages/Vectors/VectorsMain";
import RevitMain from "./Pages/Revit/RevitMain";
import Testing from "./Pages/Test";
import AboutMain from "./Pages/About/AboutMain";
import DetailMain from "./Pages/DetailPage/DetailMain";

function App() {
  // const lenis = new Lenis({
  //   smooth: true,
  //   duration: 0.6,
  //   easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
  //   direction: "vertical",
  // });

  // // lenis.on("scroll", ({ scroll, limit, velocity, direction, progress }) => {
  // //   console.log({ scroll });
  // // });

  // function raf(time) {
  //   lenis.raf(time);
  //   requestAnimationFrame(raf);
  // }
  // requestAnimationFrame(raf);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  let activeURL = window.location.pathname.slice(1, 20);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Wrapper>
          <Navbar activeURL={activeURL} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={`/${vectors}`} element={<VectorsMain />} />
            <Route path={`/${visualisation}`} element={<ProjectsMain />} />
            <Route path={`/${revit}`} element={<RevitMain />} />
            <Route path={`/${about}`} element={<AboutMain />} />
            <Route path={`/${work}/:id`} element={<DetailMain />} />
            <Route path={`/test`} element={<Testing />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
